import logo from './logo.svg';
import './App.css';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import 'semantic-ui-css/semantic.min.css'

import {Menu, Dropdown, Icon, Sidebar, Segment, Container} from 'semantic-ui-react'
import { useState } from 'react';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

// routes
import {GptChat} from './pages/gpt/index';
import {TermsIndex} from './pages/terms/index';
const Home = () => {
  return (
    <div>
      This is the homepage of a sample react application with a rails back-end.
    </div>
  );
}

// Create a client
const queryClient = new QueryClient();

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="App">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Menu>
            <Menu.Item as={Link} to='/'>
              <Icon name='home' />
              Home
            </Menu.Item>
            <Menu.Item as={Link} to='/terms'>
              <Icon name='list' />
              Terms (CRUD Example)
            </Menu.Item>
            <Menu.Item as={Link} to='/gpt'>
              <Icon name='chat' />
              GPT Chat
            </Menu.Item>
          </Menu>
          <Container>              
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/terms" element={<TermsIndex/>} />
              <Route path="/gpt" element={<GptChat/>} />
            </Routes>
          </Container>
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
