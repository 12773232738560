import axios from 'axios';
import { getHost } from './api';

export const getTerms = () => {
  // return fetch('http://localhost:3000/api/v1/terms');
  // return 
  return axios({
    method: 'get',
    url: `${getHost()}/api/v1/terms.json`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
};

export const editTerm = (data) => {
  return axios.patch(`${getHost()}/api/v1/terms/${data.id}`, data.data);
};

export const newTerm = (data) => {
  return axios.post(`${getHost()}/api/v1/terms.json`, data.data);
};

export const deleteTerm = (data) => {
  return axios.delete(`${getHost()}/api/v1/terms/${data.id}`);
};
