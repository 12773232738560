import React, {useState} from 'react';
import {Confirm, Button, Icon} from 'semantic-ui-react';
import {deleteTerm} from '../../api/terms';
import {useMutation, useQueryClient} from '@tanstack/react-query'

const TermDelete = ({term}) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: deleteTerm,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['terms'] });
      setOpen(false);
    },
  });

  const onSubmit = () => {
    mutation.mutate({
      id: term.id
    });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} negative>
        <Icon name='trash' color='white' fitted/>
      </Button>
      <Confirm
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => onSubmit()}
      />    
    </>
  );
}

export {TermDelete};