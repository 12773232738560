import React from 'react'
import {getTerms} from '../../api/terms.js'
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {TermEdit} from './edit.js'
import { TermNew } from './new.js'

import {
Table, Container, Header, Segment
} from 'semantic-ui-react'
import { TermDelete } from './delete.js'

const TermIndexRow = ({term}) => {
  const {id, name, agris_code, days_until_hold, system} = term;
  return (
    <Table.Row>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{agris_code}</Table.Cell>
      <Table.Cell>{days_until_hold}</Table.Cell>
      <Table.Cell>{system}</Table.Cell>
      <Table.Cell>Audit Log</Table.Cell>
      <Table.Cell textAlign='center'>
        <TermEdit term={term}/>
        <TermDelete term={term}/>
      </Table.Cell>
    </Table.Row>
  );
}

const TermsIndex = (props) => {
  // Access the client
  const queryClient = useQueryClient()

  // Queries
  const result = useQuery({ queryKey: ['terms'], queryFn: getTerms })

  if(result.isPending) {
    return (<div>fetching...</div>);
  }

  return (
    <Segment>
      <Header as='h1'>Terms</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Agris Code</Table.HeaderCell>
            <Table.HeaderCell>Days Until Hold</Table.HeaderCell>
            <Table.HeaderCell>System</Table.HeaderCell>
            <Table.HeaderCell>Audit Log</Table.HeaderCell>
            <Table.HeaderCell><TermNew /></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {result.data?.data.map((term) => <TermIndexRow key={term.id} term={term}/>)}
        </Table.Body>
      </Table>
    </Segment>
  )
}

export {TermsIndex};
