import axios from 'axios';
import { getHost } from './api';

const getGptMessages = (data) => {
  return axios({
    method: 'get',
    url: `${getHost()}/api/v1/gpt_chats/${data.queryKey[1]}.json`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    
    params: {
      username: data.queryKey[1],
      gpt_key: data.queryKey[2]
    }
  });
};

export const newGptMessage = (data) => {
  // console.log('newGptMessage', data);
  return axios.post(`${getHost()}/api/v1/gpt_chats.json`, {gpt_chat_line: data});
};

export { getGptMessages };
