import React from 'react'
import {useState} from 'react'
import {TermForm} from './form'

import {editTerm} from '../../api/terms'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {Button, Modal, Icon} from 'semantic-ui-react'

const TermEdit = ({term}) => {
  const [isEditing, setEditing] = useState(false);
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: editTerm,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['terms'] });
      setEditing(false);
    },
  })  

  const onSubmit = (data) => {
    mutation.mutate({
      id: term.id,
      data: data
    })    
    
  }

  return (
    <Modal
      trigger={<Button><Icon fitted name='pencil'></Icon></Button>}
      onClose={() => setEditing(false)}
      onOpen={() => setEditing(true)}
      closeIcon
      open={isEditing}
    >
      <Modal.Header>Edit Term</Modal.Header>
      <Modal.Content>
        <TermForm term={term} onSubmit={onSubmit}></TermForm>
      </Modal.Content>
    </Modal>
  );
}

export {TermEdit};