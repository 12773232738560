import React from 'react'
import {useState} from 'react'
import {Form, FormField, Button} from 'semantic-ui-react'

const TermForm = ({term, onSubmit}) => {
  const [name, setName] = useState(term?.name || '');
  const [agris_code, setAgrisCode] = useState(term?.agris_code || '');
  const [days_until_hold, setDaysUntilHold] = useState(term?.days_until_hold || '');
  const [system, setSystem] = useState(term?.system || '');
  return (
    <Form>
      <Form.Input label="Name" name="name" value={name} onChange={(e) => setName(e.target.value)}/>
      <Form.Input label="Agris Code" name="agris_code" value={agris_code} onChange={(e) => setAgrisCode(e.target.value)}/>
      <Form.Input label="Days Until Hold" name="days_until_hold" value={days_until_hold} onChange={(e) => setDaysUntilHold(e.target.value)}/>
      <Form.Input label="System" name="system" value={system} onChange={(e) => setSystem(e.target.value)}/>
      <Button primary onClick={() => onSubmit({name, agris_code, days_until_hold, system})}>Save</Button>
    </Form>
  );
}

export {TermForm};
