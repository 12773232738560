import React, {useState} from 'react'
import {getGptMessages, newGptMessage} from '../../api/gpt.js'

import {
  useQuery,
  useQueryClient,
  useMutation
} from '@tanstack/react-query'

import {
  Segment,
  Form,
  FormField,
  Input,
  Button,
  Feed,
  FeedEvent,
  FeedContent,
  FeedSummary,
  FeedUser,
  FeedDate,
  FeedExtra
} from 'semantic-ui-react'

const GptMessage = ({message}) => {
  return (
    <FeedEvent>
      <FeedContent>
        <FeedSummary>
          <FeedUser>{message.message_type}</FeedUser> said:
          <FeedDate>{message.created_at}</FeedDate>
        </FeedSummary>
        <FeedExtra text>
          {message.message}
        </FeedExtra>
      </FeedContent>
    </FeedEvent>
  );
}

const GptMessages = ({username, gptKey}) => {
  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const result = useQuery({
    queryKey: ['gpt_messages', username, gptKey],
    queryFn: getGptMessages,
    // staleTime: 2000
  })

  if(result.isPending) {
    return (<div>fetching...</div>);
  }

  // console.log('GptMessages', result.data);

  if(result.data?.data.gpt_chat_lines.length == 0) {
    return (
      <Segment>
        No messages yet.
      </Segment>
    )
  }

  return (
    <Segment>
      <Feed>
        {result.data?.data.gpt_chat_lines.map((message) => <GptMessage key={message.id} message={message}/>)}
      </Feed>
      
    </Segment>
  );
}

const GptChat = (props) => {
  const queryClient = useQueryClient();
  const [username, setUsername] = useState('user');
  const [gptKey, setGptKey] = useState('');
  const [isChatting, setIsChatting] = useState(false);
  const [message, setMessage] = useState('');
  const mutation = useMutation({
    mutationFn: newGptMessage,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['gpt_messages', username, gptKey] });
    },
  });

  const onSubmit = (event) => {
    const data = Object.fromEntries(new FormData(event.target));
    mutation.mutate({
      username: username,
      gpt_key: gptKey,
      message: data.message
    });
    event.target.reset();
    // console.log('submit', data);
  }

  const onChat = (event) => {
    const data = Object.fromEntries(new FormData(event.target));
    setUsername(data.username);
    setGptKey(data.gptKey);
    setIsChatting(true);
  }

  // submit on enter unless shift-enter
  const submitOnEnter = (event) => {
    // console.log('submitOnEnter', event, event.key, event.shiftKey);
    console.log('submitOnEnter', event.key, event.shiftKey, event.target.value);
    setMessage(message + event.target.value);
    // if(event.key === 'Enter' && !event.shiftKey) {
    //   console.log('test');
    //   event.preventDefault();
    //   event.target.form.dispatchEvent(new Event('submit', {cancelable: true}));
    //   event.target.form.dispatchEvent(new Event('reset', {cancelable: true}));
      
    // }
  }

  if(isChatting) {
    return (
      <Segment>
        <Form onSubmit={onSubmit}>
          <GptMessages username={username} gptKey={gptKey} />
          <Segment>
            <Form.TextArea name='message'/>
          </Segment>
          <Form.Button floated='right' content='Send' />
        </Form>
        <Button icon='left' onClick={() => setIsChatting(false)}>Back</Button>
      </Segment>
    )
  }

  return (
    <Segment>
      <Form onSubmit={onChat}>
        <Form.Group>
          <Form.Input label='Username' name='username' defaultValue={username}/>
          <Form.Input label='GPT Key' name='gptKey' defaultValue={gptKey}/>
          
        </Form.Group>

        <Form.Group>
          <Form.Button content='Chat' />
        </Form.Group>
      </Form>
    </Segment>    
  );
}

export {GptChat};
